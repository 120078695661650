<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.6842 14.4737V23.6842C23.6842 24.4079 23.0921 25 22.3684 25H3.94737C3.22368 25 2.63158 24.4079 2.63158 23.6842V14.4737H23.6842ZM16.4474 0C18.9868 0 21.0526 2.06579 21.0526 4.60526C21.0526 5.31579 20.8947 5.98684 20.6053 6.57895H25C25.7237 6.57895 26.3158 7.17105 26.3158 7.89474V11.8421C26.3158 12.5658 25.7237 13.1579 25 13.1579H1.31579C0.592105 13.1579 0 12.5658 0 11.8421V7.89474C0 7.17105 0.592105 6.57895 1.31579 6.57895H5.71053C5.42105 5.98684 5.26316 5.31579 5.26316 4.60526C5.26316 2.06579 7.32895 0 9.86842 0C11.1579 0 12.3289 0.526316 13.1579 1.38158C13.9868 0.526316 15.1579 0 16.4474 0ZM9.86842 2.63158C8.77632 2.63158 7.89474 3.51316 7.89474 4.60526C7.89474 5.63158 8.67105 6.47368 9.68421 6.56579H9.86842H11.8421V4.59211C11.8421 3.56579 11.0658 2.72368 10.0526 2.63158H9.86842ZM16.4474 2.63158H16.2632C15.3289 2.73684 14.5789 3.47368 14.4868 4.42105V4.60526V6.57895H16.6447C17.6447 6.47368 18.4342 5.63158 18.4342 4.60526C18.4342 3.57895 17.6579 2.73684 16.6447 2.64474H16.4605L16.4474 2.63158Z"
            fill="currentColor"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
